import { useCallback, useEffect, useRef } from "react";
import useInitialPosition from "hooks/maps/useInitialPosition";
import { MAP_MARKERS_SVG_USER } from "libraries/svgPaths";
import { LatLng } from "types/map";
import { useAppDispatch } from "redux/hooks";
import { registerInitialTripCurrentLocation } from "redux/slices/shed/shedSlice";
import useGeocoder from "hooks/maps/useGeocoder";

type AdvancedMarkerElementProps = {
  map: google.maps.Map | undefined; // Map instance passed from MapContainer
};

const MapMarkerCurrentPosition = ({ map }: AdvancedMarkerElementProps) => {
  const dispatch = useAppDispatch();
  const { decodeAddressFormat } = useGeocoder();

  const registerCurrentPosition = useCallback(
    async (myPosition: LatLng | null) =>
      myPosition &&
      dispatch(
        registerInitialTripCurrentLocation({
          latitude: myPosition.lat,
          longitude: myPosition.lng,
          formattedAddress:
            (await decodeAddressFormat(myPosition)) || "Your location",
        })
      ),
    [dispatch, decodeAddressFormat]
  );

  const { initialPosition } = useInitialPosition({
    onInitialized: registerCurrentPosition,
  });

  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!map || !initialPosition) return;

    // Create the HTML content for the AdvancedMarkerElement
    if (!contentRef.current) {
      contentRef.current = document.createElement("div");
      contentRef.current.innerHTML = `<img src="${MAP_MARKERS_SVG_USER}" alt="Marker" />`;
    }

    // Create marker only once
    if (!markerRef.current) {
      console.log("[Map] Create marker current position");
      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: initialPosition,
        content: contentRef.current,
        gmpClickable: false, // Correct property for disabling interactivity
      });
    } else {
      // Update only the marker's position
      markerRef.current.position = initialPosition;
    }

    return () => {
      // Cleanup: Remove content and marker from the map
      if (markerRef.current) {
        markerRef.current.map = null;
        markerRef.current = null;
      }
    };
  }, [map, initialPosition]);

  return null;
};

export default MapMarkerCurrentPosition;

import { Typography, Box, AppBar, Toolbar } from "@mui/material";
import CarLogoBrand from "../cars/CarLogoBrand";
import { APP_PARTNER_SVG_RAINFOREST } from "libraries/svgPaths";

type Props = {
  icon?: React.ReactElement;
  title?: string;
  carBrand?: string;
};

export const TOP_BAR_HEIGHT_PX = 56;

const AppTopBar = ({ icon, title, carBrand }: Props) => {
  return (
    <AppBar
      position="static"
      sx={{ displayPrint: "none", background: "white" }}
    >
      <Toolbar style={{ gap: 10 }}>
        {icon ? (
          icon
        ) : (
          <CarLogoBrand size="small" carBrand={carBrand ? carBrand : ""} />
        )}

        {title && (
          <Typography variant="h5" fontSize={14} color="primary">
            {title}
          </Typography>
        )}

        <Box flexGrow={1} />

        <a
          href="https://www.regnskog.no/no/stott-oss"
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="brand-logo"
            style={{ height: 30 }}
            src={APP_PARTNER_SVG_RAINFOREST}
          />
        </a>
      </Toolbar>
    </AppBar>
  );
};

export default AppTopBar;

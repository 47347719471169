import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import useBottomBar, { NAVIGATION_ITEM } from "hooks/base-app/useBottomBar";
import useIsAuthenticated from "hooks/useIsAuthenticated";
import CommuteIcon from "@mui/icons-material/Commute";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginIcon from "@mui/icons-material/Login";
import MapIcon from "@mui/icons-material/Map";

export const BOTTOM_BAR_HEIGHT_PX = 56;

const AppBottomNavigation = styled(BottomNavigation)(
  ({ theme }) => `
  background-color: ${theme.palette.primary.main};
  justify-content: space-between;
`
);

export const AppBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => `
  color: ${theme.palette.common.white};
  &.Mui-selected {
    color: ${theme.palette.secondary.main};
  }
`
);

export const BottomBarPaper = styled(Paper)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const BottomBarPlaceholder = () => (
  <AppBottomNavigation>
    <AppBottomNavigationAction />
  </AppBottomNavigation>
);

const BottomBar = () => {
  const { bottomItem, handleNavigationChange } = useBottomBar();
  const isAuthenticated = useIsAuthenticated();
  return (
    <BottomBarPaper color="primary" elevation={3}>
      <AppBottomNavigation
        showLabels
        value={bottomItem}
        onChange={handleNavigationChange}
      >
        <AppBottomNavigationAction
          sx={{ display: undefined }}
          value={NAVIGATION_ITEM.MAP}
          label="My Map"
          icon={<MapIcon />}
        />
        <AppBottomNavigationAction
          sx={{ display: isAuthenticated ? undefined : "none" }}
          value={NAVIGATION_ITEM.TRIPS}
          label="My Trips"
          icon={<CommuteIcon />}
        />
        {/*  <AppBottomNavigationAction
          sx={{ display: isAuthenticated ? undefined : "none" }}
          value={NAVIGATION_ITEM.CARS}
          label="Cars"
          icon={<DirectionsCarIcon />}
        />
        */}
        <AppBottomNavigationAction
          sx={{ display: isAuthenticated ? undefined : "none" }}
          value={NAVIGATION_ITEM.PROFILE}
          label="My Profile"
          icon={<AccountCircleIcon />}
        />
        <AppBottomNavigationAction
          sx={{ display: isAuthenticated ? "none" : undefined }}
          value={NAVIGATION_ITEM.LOGIN}
          label="Login"
          icon={<LoginIcon />}
        />
      </AppBottomNavigation>
    </BottomBarPaper>
  );
};

export default BottomBar;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
};

const RainforestPopupInfo = ({ open, onClose }: Props) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Important information"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        About the app: The intention is to help you rethink and understand CO2
        climate impact in your daily life, and then transparently provide
        insight that is easy to act on.
        <br />
        <br />
        Saving the worlds Rainforest is the easiest and cheapest way to reduce
        carbon emissions globally. Therefore we show how CO2 released into the
        atmosphere by driving, relates to trees that must be protected to make
        real environmental impact.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        Lukk
      </Button>
    </DialogActions>
  </Dialog>
);

export default RainforestPopupInfo;

import { useCallback, useEffect, useRef } from "react";
import { MapExperienceMarker } from "types/map";
import { registerExperienceDestination } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "../../redux/hooks";

type AdvancedMarkerElementProps = {
  map: google.maps.Map | undefined; // Map instance passed from MapContainer
  marker: MapExperienceMarker;
  svgMarker: string;
};

const MapMarkerExperience = ({
  map,
  marker,
  svgMarker,
}: AdvancedMarkerElementProps) => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );
  const contentRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const handleExperienceMarkerClick = useCallback(() => {
    console.log("[Map] Experience marker click", marker.name);

    dispatch(registerExperienceDestination(marker));

    // Consider adding "registerReferenceExperience"
  }, [dispatch, marker]);

  useEffect(() => {
    if (!map) return;

    // Create the HTML content for the AdvancedMarkerElement
    if (!contentRef.current) {
      contentRef.current = document.createElement("div");
      contentRef.current.innerHTML = `<img src="${svgMarker}" alt="Experience Marker" />`;
    }

    // Create marker only once
    if (!markerRef.current) {
      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: {
          lat: marker.lat,
          lng: marker.lng,
        },
        content: contentRef.current,
        title: marker.name,
      });

      markerRef.current.addListener("click", handleExperienceMarkerClick);
    }

    return () => {
      // Cleanup: Remove content and marker from the map
      if (markerRef.current) {
        markerRef.current.map = null;
        markerRef.current = null;
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [map]);

  return null;
};

export default MapMarkerExperience;

import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { TripState } from "types/user";
import useShareTrip from "hooks/trips/useShareTrip";

type Props = {
  trip: TripState;
  genericVin: string;
};

const ShareTripButton = ({ trip, genericVin }: Props) => {
  const { handleShareTrip } = useShareTrip({
    trip,
    genericVin,
  });

  return (
    <Button startIcon={<SendIcon />} onClick={handleShareTrip}>
      Share
    </Button>
  );
};

export default ShareTripButton;

import { useEffect, useState } from "react";
import { LatLng } from "types/map";

type Props = {
  onInitialized: (position: LatLng | null) => void;
};

type ReturnType = {
  initialPosition: LatLng | null;
};

const useInitialPosition = ({ onInitialized }: Props): ReturnType => {
  const [initialPosition, setInitialPosition] = useState<LatLng | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      console.warn(
        "[useInitialPosition] Navigation Geo Location feature unavailable"
      );
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setInitialPosition(currentPosition);
        onInitialized(currentPosition);
      },
      () => console.warn("[useInitialPosition] Geo location access refused")
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return { initialPosition };
};

export default useInitialPosition;

import { styled, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import BottomBar, { BOTTOM_BAR_HEIGHT_PX } from "components/base-app/BottomBar";

const HORIZONTAL_GUTTER_PX = 16;

export const AppContainerWithBottomBar = styled(Container)(({ theme }) => ({
  paddingTop: `${HORIZONTAL_GUTTER_PX}px`,
  paddingBottom: `${HORIZONTAL_GUTTER_PX + BOTTOM_BAR_HEIGHT_PX}px`,
}));

const AppWithBottomBar = () => (
  <>
    <Outlet />
    <BottomBar />
  </>
);

export default AppWithBottomBar;

import { Outlet, BrowserRouter, Routes, Route } from "react-router-dom";

import AppWithBottomBar from "components/base-app/AppWithBottomBar";
import CarsPage from "components/cars/CarsPage";
import CarPage from "components/cars/CarPage";
import LoginMagicCallback from "components/base-app/LoginMagicCallback";
import MapPage from "components/map/MapPage";
import ProfilePage from "components/profile/ProfilePage";
import ThankYouPage from "components/payment/ThankYouPage";
import PaymentFallbackPage from "components/payment/PaymentFallbackPage";
import LoginMagicLinkPage from "components/base-app/LoginMagicLinkPage";
import LoginEmailAndPasswordPage from "components/login/LoginEmailAndPasswordPage";
import AppAuthenticationPipe from "components/base-app/AppAuthenticationPipe";
import AppHomeLoaderPipe from "components/base-app/AppHomeLoaderPipe";
import TripsDashboard from "components/trips/TripsDashboard";
import PaymentTermsPage from "components/payment/PaymentTermsPage";
import ResetPassword from "components/login/ResetPassword";
import ForgetPasswordPage from "components/login/ForgetPaswordPage";
import CreateNewUserPage from "components/login/CreateNewUserPage";

enum BASE_ROUTE {
  MAGIC_LINK_CONFIRM = "login/magic-link-confirm",
  LOGIN_MAGIC_LINK = "login/magic-link",
  CAR_PAGE = "car/:id",
  CARS_PAGE = "cars",
  MAP = "map",
  PAYMENT_VIPPS_CALLBACK = "payment-vipps-callback",
  PAYMENT_VIPPS_FALLBACK = "payment-vipps-fallback/:orderId",
  PROFILE_PAGE = "profile",
  LOG_IN_EMAIL_AND_PASSWORD = "login/email-and-password",
  TRIPS_DASHBOARD = "trips",
  PAYMENT_TERMS = "terms-payment",
  RESET_PASSWORD = "reset-password",
  FORGET_PASSWORD = "forget-password",
  NEW_USER = "new-user",
}
export enum ROUTE {
  HOME = "/",
  // Home routes (MyUser)
  CAR_PAGE = "/h/car/:id",
  CARS_PAGE = "/h/cars",
  MAP = "/h/map",
  PROFILE_PAGE = "/h/profile",
  TRIPS_DASHBOARD = "/h/trips",
  // Utility Routes
  MAGIC_LINK_CONFIRM = "/_/login/magic-link-confirm",
  LOGIN_MAGIC_LINK = "/_/login/magic-link",
  PAYMENT_VIPPS_CALLBACK = "/_/payment-vipps-callback",
  PAYMENT_VIPPS_FALLBACK = "/_/payment-vipps-fallback",
  LOG_IN_EMAIL_AND_PASSWORD = "/_/login/email-and-password",
  PAYMENT_TERMS = "_/terms-payment",
  RESET_PASSWORD = "_/reset-password",
  FORGET_PASSWORD = "_/forget-password",
  NEW_USER = "_/new-user",
}

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTE.HOME} element={<Outlet />}>
          <Route index element={<AppAuthenticationPipe />} />

          <Route path="h" element={<AppHomeLoaderPipe />}>
            <Route path={BASE_ROUTE.MAP} element={<MapPage />} />

            <Route path={BASE_ROUTE.CARS_PAGE} element={<CarsPage />} />
            <Route path={BASE_ROUTE.CAR_PAGE} element={<CarPage />} />
            <Route path={BASE_ROUTE.PROFILE_PAGE} element={<ProfilePage />} />
            <Route
              path={BASE_ROUTE.TRIPS_DASHBOARD}
              element={<TripsDashboard />}
            />
          </Route>

          <Route path="_" element={<AppWithBottomBar />}>
            <Route path={BASE_ROUTE.NEW_USER} element={<CreateNewUserPage />} />
            <Route
              path={BASE_ROUTE.PAYMENT_TERMS}
              element={<PaymentTermsPage />}
            />
            <Route
              path={BASE_ROUTE.RESET_PASSWORD}
              element={<ResetPassword />}
            />
            <Route
              path={BASE_ROUTE.FORGET_PASSWORD}
              element={<ForgetPasswordPage />}
            />
            <Route
              path={BASE_ROUTE.PAYMENT_VIPPS_CALLBACK}
              element={<ThankYouPage />}
            />
            <Route
              path={BASE_ROUTE.PAYMENT_VIPPS_FALLBACK}
              element={<PaymentFallbackPage />}
            />
            <Route
              path={BASE_ROUTE.LOGIN_MAGIC_LINK}
              element={<LoginMagicLinkPage />}
            />
            <Route
              path={BASE_ROUTE.MAGIC_LINK_CONFIRM}
              element={<LoginMagicCallback />}
            />

            <Route
              path={BASE_ROUTE.LOG_IN_EMAIL_AND_PASSWORD}
              element={<LoginEmailAndPasswordPage />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;

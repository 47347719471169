import { useCallback, useState } from "react";
import { styled, Box, Paper, Stack } from "@mui/material";
import MapLocationField from "components/map/MapLocationField";
import { MapLocation } from "types/map";
import { useAppDispatch } from "redux/hooks";
import useCarSearch from "hooks/useCarSearch";
import CarBrandLoader from "components/cars/CarBrandLoader";
import CarSelectReference from "components/cars/CarSelectReference";
import {
  registerReferenceTripLocation,
  clearItinerary,
  clearTrip,
} from "redux/slices/shed/shedSlice";
import MapTripDetails from "components/map/MapTripDetails";
import MapToolbarCollapse from "components/map/MapToolbarCollapse";

const PANEL_WIDTH_SM_PX = 300;
const PANEL_WIDTH_MD_PX = 400;

const PaperPanel = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    // Mobile viewport size
    top: 0,
    left: 0,
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    // Tablet and larger viewport size
    top: 0,
    left: 0,
    height: "100%",
    width: PANEL_WIDTH_SM_PX,
  },
  [theme.breakpoints.up("md")]: {
    width: PANEL_WIDTH_MD_PX,
  },
  borderRadius: 0,
  position: "fixed",
  padding: theme.spacing(1),
  overflow: "auto",
}));

type Props = {
  locationFrom: MapLocation;
  locationTo: MapLocation;
};

const MapPanelContainer = ({ locationFrom, locationTo }: Props) => {
  const [selectedValue, setSelectedValue] = useState("");

  const { smoothLoading, handleSearchCar } = useCarSearch();

  const [isMinimized, setIsMinimized] = useState(false);

  const dispatch = useAppDispatch();

  const handleLocationFromChange = useCallback(
    (newFromLocation: MapLocation) =>
      dispatch(
        registerReferenceTripLocation({
          fromLatitude: newFromLocation.latitude ?? 0,
          fromLongitude: newFromLocation.longitude ?? 0,
          fromAddress: newFromLocation.formattedAddress ?? "",
        })
      ),
    [dispatch]
  );

  const handleLocationToChange = useCallback(
    (newToLocation: MapLocation) =>
      dispatch(
        registerReferenceTripLocation({
          toLatitude: newToLocation.latitude ?? undefined,
          toLongitude: newToLocation.longitude ?? undefined,
          toAddress: newToLocation.formattedAddress ?? undefined,
        })
      ),
    [dispatch]
  );

  const toggleMinimize = useCallback(() => {
    setIsMinimized(!isMinimized);
  }, [isMinimized]);

  const handleClearTrip = useCallback(() => {
    setIsMinimized(false);
    dispatch(clearTrip());
    dispatch(clearItinerary());
  }, [dispatch]);

  return (
    <Box>
      <PaperPanel elevation={3}>
        <MapToolbarCollapse
          onClearTrip={handleClearTrip}
          isMinimized={isMinimized}
          onToggle={toggleMinimize}
        >
          <Stack width="100%" mt={1}>
            <CarSelectReference
              size="small"
              onSearchCar={handleSearchCar}
              onInputChange={setSelectedValue}
              inputValue={selectedValue}
            />
            <MapLocationField
              label="From"
              location={locationFrom}
              onChange={handleLocationFromChange}
            />
            <MapLocationField
              label="To"
              location={locationTo}
              onChange={handleLocationToChange}
            />
            <Box mb={1} />
            <CarBrandLoader loading={smoothLoading} />
          </Stack>
        </MapToolbarCollapse>
        <MapTripDetails />
      </PaperPanel>
    </Box>
  );
};

export default MapPanelContainer;

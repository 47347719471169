import React from "react";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Stack, Box, Button, styled, Typography } from "@mui/material";
import { AppContainerWithBottomBar } from "components/base-app/AppWithBottomBar";
import { ROUTE } from "components/base-app/AppRoutes";

const StyledImg = styled("img")`
  width: 50%;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 2px;
  margin-bottom: 2px;
  max-width: 200px;
`;

const ThankYouPage = () => (
  <AppContainerWithBottomBar>
    <Stack textAlign="center" justifyContent="center">
      <Box textAlign="center" maxWidth="100">
        <StyledImg
          alt="brand-logo"
          src={`${process.env.PUBLIC_URL}/logo_NO_horizontal_black.svg`}
        />
      </Box>
      <Box my={2}>
        <Typography variant="h5">Thank you for you donation.</Typography>
        <Typography variant="body2" color="text.secondary">
          Payment successful.
        </Typography>
      </Box>
      <Box>
        <Button
          startIcon={<AccountCircleIcon />}
          variant="contained"
          color="success"
          component={Link}
          to={ROUTE.PROFILE_PAGE}
        >
          Your Forest
        </Button>
      </Box>
    </Stack>
  </AppContainerWithBottomBar>
);

export default ThankYouPage;

import { useCallback } from "react";
import { Button } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { TripState } from "types/user";
import { selectCarByGenericVin } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";
import useAppNavigate from "hooks/useAppNavigate";
import { ROUTE } from "components/base-app/AppRoutes";
import {
  registerReferenceCar,
  registerReferenceTripSelection,
} from "redux/slices/shed/shedSlice";

type Props = {
  trip: TripState;
};

const TripMapNavigate = ({ trip }: Props) => {
  const { genericVin } = trip;
  const tripCar = useSelector(selectCarByGenericVin(genericVin));

  const dispatch = useAppDispatch();
  const navigateTo = useAppNavigate();

  const handleMapClick = useCallback(() => {
    if (!tripCar) {
      return;
    }

    dispatch(registerReferenceCar(tripCar));
    dispatch(registerReferenceTripSelection(trip));
    navigateTo(ROUTE.MAP);
  }, [navigateTo, dispatch, tripCar, trip]);

  return (
    <Button
      startIcon={<MapIcon />}
      disabled={!tripCar}
      onClick={handleMapClick}
    >
      Map
    </Button>
  );
};

export default TripMapNavigate;

import { useState } from "react";
import { CONFIRM_PASSWORD_RESET } from "graphql/queries";
import { useMutation } from "@apollo/client";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AppTopBar from "components/base-app/AppTopBar";
import {
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Button,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import { AppContainerWithBottomBar } from "components/base-app/AppWithBottomBar";

const ResetPassword = () => {
  const [confirmPasswordReset] = useMutation(CONFIRM_PASSWORD_RESET);
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const confirmPasswordChange = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const mode = urlParams.get("mode");
    const oobCode = urlParams.get("oobCode");

    if (mode === "resetPassword" && oobCode) {
      const confirmPasswordResponse = await confirmPasswordReset({
        variables: {
          oobCode: oobCode,
          newPassword: password,
        },
      });

      console.log(confirmPasswordResponse);
    }
  };

  return (
    <>
      <AppTopBar icon={<VpnKeyIcon color="primary" />} title="Reset password" />
      <AppContainerWithBottomBar>
        <Box mt={2} display="flex" justifyContent="center">
          <FormControl variant="outlined">
            <OutlinedInput
              id="reset-password-input"
              value={password}
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              }
              aria-describedby="reset-password-input"
              inputProps={{
                "aria-label": "password",
                type: "password",
              }}
            />
            <FormHelperText id="reset-password-input">
              Set new password
            </FormHelperText>
          </FormControl>
          <Button onClick={confirmPasswordChange}>SUBMIT</Button>
        </Box>
      </AppContainerWithBottomBar>
    </>
  );
};

export default ResetPassword;

import { MapExperienceMarker } from "types/map";
import MapMarkerExperience from "components/map/MapMarkerExperience";
import { MAP_MARKERS_SVG_BASE } from "libraries/svgPaths";
import { useAppSelector } from "redux/hooks";
import { selectReferenceExperience } from "redux/slices/shed/shedSlice";

type AdvancedMarkerElementProps = {
  map: google.maps.Map | undefined; // Map instance passed from MapContainer
};

const MapMarkersExperience = ({ map }: AdvancedMarkerElementProps) => {
  const mapExperience = useAppSelector(selectReferenceExperience);
  const markers = mapExperience?.markers ?? [];
  const experienceId = mapExperience?.experienceId ?? "";

  return (
    <>
      {markers.map((experience: MapExperienceMarker, index: number) => (
        <MapMarkerExperience
          key={experienceId + index}
          map={map}
          marker={experience}
          svgMarker={`${MAP_MARKERS_SVG_BASE}${experienceId}.svg`}
        />
      ))}
    </>
  );
};

export default MapMarkersExperience;

import { useCallback } from "react";
import { SAVE_TRIP } from "graphql/queries";
import { useMutation } from "@apollo/client";
import { TripState } from "types/user";

type ReturnType = {
  handleSaveTrip: (trip: TripState) => Promise<TripState>;
  loading: boolean;
};

const useSaveTrip = (): ReturnType => {
  const [saveTrip, { loading }] = useMutation(SAVE_TRIP, {
    fetchPolicy: "network-only",
  });

  const handleSaveTrip = useCallback(
    async (trip: TripState) => {
      const { data } = await saveTrip({
        variables: {
          trip: {
            carId: trip.carId,
            genericVin: trip.genericVin,
            commuteFrequency: trip.commuteFrequency ?? 1,
            distanceMeters: trip.distanceMeters,
            distanceLabel: trip.distanceLabel,
            durationSeconds: trip.durationSeconds,
            fromAddress: trip.fromAddress,
            fromLatitude: trip.fromLatitude,
            fromLongitude: trip.fromLongitude,
            licensePlate: trip.licensePlate,
            toAddress: trip.toAddress,
            toLatitude: trip.toLatitude,
            toLongitude: trip.toLongitude,
            tripId: trip.tripId,
          },
        },
      });
      return data.addTrip;
    },
    [saveTrip]
  );

  return { handleSaveTrip, loading };
};

export default useSaveTrip;

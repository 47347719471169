import { TextField, InputAdornment, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

type Props = {
  commuteFrequency: number;
  increaseFrequency: () => void;
  decreaseFrequency: () => void;
};

const TripCommuteFrequency = ({
  commuteFrequency,
  increaseFrequency,
  decreaseFrequency,
}: Props) => (
  <TextField
    label="Trip frequency"
    value={commuteFrequency}
    sx={{ "& .MuiInputBase-input": { textAlign: "center" } }}
    size="small"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <IconButton
            size="small"
            disabled={commuteFrequency <= 1}
            onClick={decreaseFrequency}
          >
            <RemoveIcon />
          </IconButton>
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton size="small" onClick={increaseFrequency}>
            <AddIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export default TripCommuteFrequency;

import {
  useRef,
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader } from "@googlemaps/js-api-loader";

type MapLibraries = typeof google.maps;
type PromiserResolver = (value: MapLibraries | null) => void;

interface MapContextInterface {
  initialized: boolean;
  asyncLibraryLoader: Promise<MapLibraries | null> | null;
  mapsLibraries: MapLibraries | null;
}

const mapContextDefaultValue: MapContextInterface = {
  initialized: false,
  asyncLibraryLoader: null,
  mapsLibraries: null,
};

export const MapContext = createContext<MapContextInterface>(
  mapContextDefaultValue
);

const MapProvider = ({ children }: { children: ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const loaderResolver = useRef<PromiserResolver | null>(null);

  const [asyncLibraryLoader] = useState<Promise<MapLibraries | null>>(
    new Promise((resolve) => {
      loaderResolver.current = resolve;
    })
  );

  const [mapsLibraries, setMapsLibraries] = useState<MapLibraries | null>(null);

  useEffect(() => {
    const googleApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    if (!googleApiKey) {
      console.error("[Provider] MapProvider - loader no API_KEY env set");

      setInitialized(false);
      loaderResolver.current && loaderResolver.current(null);
      return;
    }

    const loadLibraries = async () => {
      const googleLoader = new Loader({
        apiKey: googleApiKey,
        version: "weekly",
        libraries: ["maps", "places", "routes", "marker", "geocoding"],
      });
      try {
        const { maps } = await googleLoader.load();
        console.log(
          "[Provider] MapProvider - googleLoader initialization success"
        );
        setMapsLibraries(maps);
        setInitialized(true);
        loaderResolver.current && loaderResolver.current(maps);
      } catch (error: unknown) {
        setInitialized(false);
        loaderResolver.current && loaderResolver.current(null);
        console.error(
          "[Provider] MapProvider - googleLoader initialization failure",
          error
        );
      }
    };

    loadLibraries();
  }, []);

  const contextValue = useMemo(
    () => ({
      asyncLibraryLoader,
      mapsLibraries,
      initialized,
    }),
    [asyncLibraryLoader, initialized, mapsLibraries]
  );

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
};

export default MapProvider;

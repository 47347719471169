import React from "react";
import {
  Stack,
  Slide,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

import {
  selectMyCars,
  selectIsUserLoaded,
} from "../../redux/slices/shed/shedSlice";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { useBoolean } from "../../hooks/core/useBoolean";
import AppTopBar from "../base-app/AppTopBar";
import { AppContainerWithBottomBar } from "components/base-app/AppWithBottomBar";
import CategoriesFab from "./CarsFab";
import CarOverview from "./CarOverview";
import CarOverviewSkeleton from "./CarOverviewSkeleton";
import BottomBar from "components/base-app/BottomBar";

import { TransitionProps } from "@mui/material/transitions";
import { CarState } from "../../types/cars";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CarsPage = () => {
  const {
    value: carFormOpen,
    setTrue: openCarForm,
    setFalse: closeCarForm,
  } = useBoolean(false);

  const myCars = useSelector(selectMyCars);
  const isUserLoaded = useSelector(selectIsUserLoaded);

  const isAuthenticated = useIsAuthenticated();
  const loading = isAuthenticated && !isUserLoaded;

  return (
    <>
      <AppTopBar
        icon={<DirectionsCarIcon color="primary" />}
        title="Your Cars"
      />
      <AppContainerWithBottomBar>
        {loading ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <CarOverviewSkeleton />
            <CarOverviewSkeleton />
            <CarOverviewSkeleton />
          </Stack>
        ) : (
          <Grid container spacing={3}>
            {myCars.map((car: CarState, index: number) => (
              <Grid key={index} item xs={4} sm={3} lg={2}>
                <CarOverview car={car} />
              </Grid>
            ))}
          </Grid>
        )}
      </AppContainerWithBottomBar>
      <CategoriesFab onClick={openCarForm} />
      <Dialog
        fullScreen
        open={carFormOpen}
        onClose={closeCarForm}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeCarForm}
              aria-label="close"
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Dialog>
      <BottomBar />
    </>
  );
};

export default CarsPage;

import { TripShare, TripLocation, TripState } from "types/user";
import { EXPERIENCE_ID } from "types/map";

export const getNeutralTrip = (): TripState => ({
  carId: "",
  genericVin: "",
  licensePlate: "",
  distanceMeters: 0,
  distanceLabel: "",
  durationSeconds: 0,
  fromAddress: "",
  fromLatitude: 0,
  fromLongitude: 0,
  toAddress: "",
  toLatitude: 0,
  toLongitude: 0,
  tripId: null,
  tripTimeStamp: "",
  commuteFrequency: 1,
});

export const tripStateToTripLocation = ({
  fromAddress,
  fromLatitude,
  fromLongitude,
  toAddress,
  toLatitude,
  toLongitude,
}: TripState): TripLocation => ({
  fromAddress,
  fromLatitude,
  fromLongitude,
  toAddress,
  toLatitude,
  toLongitude,
});

export const tripStateToTripShare = (
  {
    fromAddress,
    fromLatitude,
    fromLongitude,
    toAddress,
    toLatitude,
    toLongitude,
  }: TripLocation,
  experienceId: EXPERIENCE_ID,
  genericVin: string
): TripShare => ({
  fromLabel: fromAddress,
  fromCoordinates: `${fromLatitude};${fromLongitude}`,
  toLabel: toAddress,
  toCoordinates: `${toLatitude};${toLongitude}`,
  genericVin,
  experienceId,
});

import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  createFilterOptions,
} from "@mui/material";
import CarSelectRenderOption from "components/cars/CarSelectRenderOption";
import useCarSelectReference, {
  CarStateOption,
} from "hooks/cars/useCarSelectReference";
import { omit } from "lodash/fp";

type Props = {
  size: "small";
  inputValue: string;
  onSearchCar: (licensePlate: string) => void;
  onInputChange: (newInputValue: string) => void;
};

const filterOptions = createFilterOptions<CarStateOption>({
  matchFrom: "any",
  stringify: (option) => `${option.carBrand ?? ""} ${option.carModel ?? ""}`,
});

const CarSelectReference = ({
  onSearchCar,
  size,
  inputValue,
  onInputChange,
}: Props) => {
  const { handleAddSearchOption, carOptions, handleSelection, handleBlur } =
    useCarSelectReference({
      inputValue,
      onSearchCar,
    });

  return (
    <Box display="flex" flexWrap="wrap">
      <FormControl fullWidth variant="standard">
        <Autocomplete
          id="car-reference-selector"
          options={carOptions}
          onChange={handleSelection}
          onBlur={handleBlur}
          getOptionLabel={(car: CarStateOption) =>
            ` ${car.carBrand} ${car.carModel} `
          }
          renderOption={(
            renderProps,
            {
              genericVin,
              carBrand,
              carModel,
              licensePlate,
              inputValue,
            }: CarStateOption
          ) => (
            <Box
              key={genericVin ? genericVin : licensePlate}
              component="li"
              {...omit(["key"])(renderProps)}
            >
              <CarSelectRenderOption
                carBrand={carBrand ?? ""}
                carModel={carModel ?? ""}
                licensePlate={licensePlate ?? ""}
                inputValue={inputValue ?? ""}
              />
            </Box>
          )}
          filterOptions={(options: CarStateOption[], params) =>
            handleAddSearchOption(
              filterOptions(options, params),
              params.inputValue
            )
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => onInputChange(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a car"
              variant="filled"
              size={size}
              autoComplete="off"
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default CarSelectReference;

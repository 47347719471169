import { useEffect } from "react";
import { ROUTE } from "components/base-app/AppRoutes";
import useIsAuthenticated from "hooks/useIsAuthenticated";
import useAppNavigate from "hooks/useAppNavigate";

const AppAuthenticationPipe = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigateTo = useAppNavigate();

  useEffect(() => {
    if (isAuthenticated == null) {
      return;
    }
    navigateTo(ROUTE.MAP);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isAuthenticated]);

  return null;
};

export default AppAuthenticationPipe;

import { Collapse, styled, Box, Button } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveTripButton from "components/cars/SaveTripButton";
import ShareTripButton from "components/cars/ShareTripButton";
import { useAppSelector } from "redux/hooks";
import {
  selectReferenceItinerary,
  selectReferenceTrip,
  selectReferenceCar,
} from "redux/slices/shed/shedSlice";

const CollapseToolbar = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
});

type Props = {
  isMinimized: boolean;
  onToggle: () => void;
  onClearTrip: () => void;
  children: React.ReactElement;
};

const MapToolbarCollapse = ({
  children,
  onClearTrip,
  isMinimized,
  onToggle,
}: Props) => {
  const itinerary = useAppSelector(selectReferenceItinerary);
  const trip = useAppSelector(selectReferenceTrip);
  const referenceCar = useAppSelector(selectReferenceCar);

  return (
    <>
      <CollapseToolbar>
        <Button startIcon={<MapIcon />} onClick={onClearTrip}>
          New
        </Button>
        <SaveTripButton itinerary={itinerary} />
        <ShareTripButton
          trip={trip}
          genericVin={referenceCar?.genericVin ?? ""}
        />
        <Button
          startIcon={isMinimized ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          onClick={onToggle}
        >
          {isMinimized ? "Show" : "Hide"}
        </Button>
      </CollapseToolbar>
      <Collapse in={!isMinimized}>{children}</Collapse>
    </>
  );
};

export default MapToolbarCollapse;

import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material";
import { GoogleMap } from "types/map";
import useMapRenderer from "hooks/maps/useMapRenderer";
import MapMarkerCurrentPosition from "components/map/MapMarkerCurrentPosition";
import MapMarkersExperience from "components/map/MapMarkersExperience";

const MapBoundaries = styled("div")`
  height: 100vh;
  width: 100vw;
`;

/*
 * SDK MapOptions documentation:
 *
 * https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
 */
interface Props extends google.maps.MapOptions {
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onIdle?: (map: GoogleMap) => void;
  children: React.ReactElement[];
}

const MapContainer = ({
  children,
  onClick,
  onIdle,
  ...googleMapOptions
}: Props) => {
  const wrapperReference = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<GoogleMap>();

  useMapRenderer({
    onMapInitialized: setMap,
    mapWrapperReference: wrapperReference,
    googleMapOptions,
  });

  // Register det events "click" and "idle"
  // Full list: https://developers.google.com/maps/documentation/javascript/events
  useEffect(() => {
    map && google.maps.event.clearListeners(map, "click");
    map && google.maps.event.clearListeners(map, "idle");
    map && onClick && map.addListener("click", onClick);
    map && onIdle && map.addListener("idle", () => onIdle(map));
  }, [map, onClick, onIdle]);

  return (
    <>
      <MapBoundaries ref={wrapperReference} />
      <MapMarkerCurrentPosition map={map} />
      <MapMarkersExperience map={map} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map } as any);
        }
      })}
    </>
  );
};

export default MapContainer;

import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Alert,
} from "@mui/material";
import { AppContainerWithBottomBar } from "components/base-app/AppWithBottomBar";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EmailIcon from "@mui/icons-material/Email";
import InboxIcon from "@mui/icons-material/Inbox";

import { SIGNIN } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";

import AppTopBar from "../base-app/AppTopBar";
import { ROUTE } from "../base-app/AppRoutes";

import SubmitButton from "../login/SubmitButton";

const LoginMagicLinkPage = () => {
  const [email, setEmail] = useState("");
  const [inboxReady, setInboxReady] = useState(false);

  const [signignEmailLink, { loading }] = useLazyQuery(SIGNIN);

  type DEVICE_TYPE = "IOS" | "ANDROID" | "WEB" | "";

  const processEmail = useCallback(
    async (email: string) => {
      try {
        const redirectUrl = new URL(
          ROUTE.MAGIC_LINK_CONFIRM,
          window.location.origin
        ).toString();

        // const userAgent = window.navigator.userAgent;
        // TODO Parse userAgent (And potentially capacitor plugin?)
        // Parser could be abstracted in a library
        const device: DEVICE_TYPE = "";

        const {
          data: { signInCreateLink },
        } = await signignEmailLink({
          variables: { email, redirectUrl, device },
        });
        if (!signInCreateLink) {
          throw new Error("GraphQL signInCreateLink");
        }
        localStorage.setItem("login-email", email);
        setEmail("");
        setInboxReady(true);
      } catch (error) {
        console.error(
          `[Component] LoginMagicLinkPage - Error sending email link`,
          error
        );
        setInboxReady(false);
      }
    },
    [signignEmailLink]
  );

  const handleSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      // Check if email is a confirmation link
      const CONFIRMATION_LINK_REGEXP = /^https:\/\/.*.firebaseapp.com/;

      if (CONFIRMATION_LINK_REGEXP.test(email)) {
        window.location.href = email;
      } else {
        processEmail(email);
      }
    },
    [email, processEmail]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setEmail(event.target.value),
    []
  );
  return (
    <>
      <AppTopBar icon={<VpnKeyIcon color="primary" />} title="Sign in" />
      <AppContainerWithBottomBar>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ mt: 2 }}
          component="form"
        >
          <Box display="flex" alignContent="center">
            <Typography my={3}>Enter your email to login or sign up</Typography>
          </Box>
          <Box display="flex" gap={2}>
            <FormControl variant="outlined">
              <OutlinedInput
                id="email-input"
                value={email}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                }
                aria-describedby="email-input"
                inputProps={{
                  "aria-label": "email",
                  type: "email",
                }}
              />
              <FormHelperText id="email-input">Your email</FormHelperText>
            </FormControl>

            <SubmitButton
              handleSubmit={handleSubmit}
              loading={loading}
            ></SubmitButton>
          </Box>
          {inboxReady && (
            <Alert
              icon={<InboxIcon fontSize="inherit" />}
              severity="success"
              onClose={() => setInboxReady(false)}
            >
              Email sent • Please check your inbox
            </Alert>
          )}
        </Grid>
      </AppContainerWithBottomBar>
    </>
  );
};

export default LoginMagicLinkPage;

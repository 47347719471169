import { useEffect, useCallback, useState, useRef } from "react";
import { styled, TextField } from "@mui/material";
import { MapLocation } from "../../types/map";
import useAutocomplete, {
  Autocomplete,
} from "../../hooks/maps/useAutocomplete";
import useMapLocationDisplay from "../../hooks/maps/useMapLocationDisplay";

const SearchField = styled(TextField)(() => ({
  flexGrow: 1,
}));

type Props = {
  label: "From" | "To";
  location: MapLocation;
  onChange: (newLocation: MapLocation) => void;
};

const useHandlePlaceChange = (
  onChange: (newLocation: MapLocation) => void,
  setLocationText: (PRISTINE_VALUE: any) => void
) => {
  const autocompleteRef = useRef<Autocomplete | null>(null);

  const handleInitialized = useCallback((autocomplete: Autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  const handlePlaceChange = useCallback(
    (autocomplete: Autocomplete) => {
      const { formatted_address, geometry } = autocomplete.getPlace();

      console.log("[Map] Location place selected", formatted_address);
      setLocationText(PRISTINE_VALUE);
      onChange({
        formattedAddress: formatted_address ?? "",
        latitude: geometry?.location ? geometry.location.lat() : null,
        longitude: geometry?.location ? geometry.location.lng() : null,
      });
    },
    [onChange, setLocationText]
  );

  return { handleInitialized, handlePlaceChange };
};

const PRISTINE_VALUE = null;

const MapLocationField = ({ label, location, onChange }: Props) => {
  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  //TODO:
  // set the text in registerReferenceTripLocation instead?
  // Check If label is "From" or "TO", and then update redux with registerReferenceTripLocation?
  // That way we remove locationText as local state here?

  const [locationText, setLocationText] = useState<string | null>(
    PRISTINE_VALUE
  );

  useEffect(() => {
    setLocationText(PRISTINE_VALUE);
  }, [location]);

  const displayText = useMapLocationDisplay({
    label,
    locationText,
  });

  const { handleInitialized, handlePlaceChange } = useHandlePlaceChange(
    onChange,
    setLocationText
  );

  useAutocomplete({
    referenceBinding: searchFieldRef,
    onInitialized: handleInitialized,
    onPlaceChange: handlePlaceChange,
  });

  return (
    <SearchField
      inputRef={searchFieldRef}
      variant="filled"
      label={label}
      value={displayText}
      onFocus={(event: React.FocusEvent<HTMLInputElement>) =>
        event.target.select()
      }
      onBlur={() => {
        setLocationText(location.formattedAddress ?? PRISTINE_VALUE);
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setLocationText(event.target.value)
      }
    />
  );
};

export default MapLocationField;

import { Box } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppTopBar from "../base-app/AppTopBar";
import ProfileDashboard from "../payment/ProfileDashboard";
import BottomBar from "components/base-app/BottomBar";
import LogOutButton from "../login/LogOutButton";
import { AppContainerWithBottomBar } from "components/base-app/AppWithBottomBar";
import ProfileTerms from "./ProfileTerms";

const ProfilePage = () => (
  <>
    <AppTopBar
      icon={<AccountCircleIcon color="primary" />}
      title={"Your Forest"}
    />
    <AppContainerWithBottomBar>
      <ProfileDashboard />
      <Box flexGrow={1} />
      <ProfileTerms />
      <Box margin="auto" mt={1}>
        <LogOutButton />
      </Box>
    </AppContainerWithBottomBar>
    <BottomBar />
  </>
);
export default ProfilePage;

import { useNavigate } from "react-router";
import { ROUTE } from "components/base-app/AppRoutes";

type NavigateToFunction = (path: ROUTE) => void;

const useAppNavigate = (): NavigateToFunction => {
  const navigate = useNavigate();

  const navigateTo = (route: ROUTE) => navigate(route);

  return navigateTo;
};

export default useAppNavigate;
